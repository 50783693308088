import React from 'react';
import { Box } from '@mui/material';

import bgImage from '../../../images/Booking-bg.png';

const BookNow = ({ tourSlug }) => {
  return (
    <Box
      className="book-now"
      sx={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <Box className="book-now-gradiant-bg"></Box>
      <Box className="book-now-content">
        <h3>
          Unlock New Experiences <br />
          Book Now for the Trip of a Lifetime
        </h3>

        <a href={`/tours/${tourSlug}/booking`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
          Book Now
        </a>
      </Box>
    </Box>
  );
};

export default BookNow;
