import React from 'react';
import { InView } from 'react-intersection-observer';

import Itinerary from './Itinerary';

const MainItineraryHeader = () => <h5 className="title">Main Itinerary</h5>;

const MainItinerary = ({ setActiveSection, itineraryData, tourStartDate, locationPins }) => {
  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  const locationsPinsWithoutExtendedItinerary = locationPins.filter(
    (locationPin) => !(locationPin.isPostExtension || locationPin.isPreExtension)
  );

  const mainItineraryData = itineraryData.map((itinerary, index) => {
    return {
      ...itinerary,
      day: index + 1,
    };
  });

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="main-itinerary"
      className="itinerary"
      as="section"
    >
      <Itinerary
        itineraryType="main"
        itineraryData={mainItineraryData}
        locationPins={locationsPinsWithoutExtendedItinerary}
        tourStartDate={tourStartDate}
        header={<MainItineraryHeader />}
      />
    </InView>
  );
};

export default MainItinerary;
