import React from 'react';
import { Box } from '@mui/material';
import { format, addDays } from 'date-fns';
import { RichText } from 'prismic-reactjs';

import Map from './Map';
import Tabs from '../../../Tabs';
import Grid from '../../../Grid';
import ImageGallery from '../ImageGallery';

export const OverviewPanel = ({ locationPins }) => {
  return (
    <Grid>
      <Grid.Col md="2 / span 10" xs="1 / span 2">
        <Tabs.Panel>
          <Map locationPins={locationPins} />
        </Tabs.Panel>
      </Grid.Col>
    </Grid>
  );
};

export const DailyItineraryPanels = ({ itineraries, tourStartDate }) => {
  return (
    <>
      {itineraries.map((itinerary, index) => {
        return (
          <Tabs.Panel key={index}>
            <Grid>
              {itinerary.image.url && (
                <Grid.Col md="2 / span 5" xs="1 / span 2">
                  <img
                    src={itinerary.image.fluid?.src || itinerary.image.url}
                    alt={itinerary.image.alt || 'Itinerary Image'}
                    style={{
                      width: '100%',
                    }}
                  />
                </Grid.Col>
              )}
              <Grid.Col
                md={itinerary.image.url ? '7 / span 5' : '2 / span 10'}
                xs="1 / span 2"
                sx={{
                  padding: {
                    xs: '0 15px',
                    md: '0',
                  },
                }}
              >
                <h4
                  className="uppercase"
                  style={{
                    fontSize: '1.125rem',
                  }}
                >
                  Day {itinerary.day}
                </h4>

                <Box
                  sx={{
                    margin: '0.25rem 0 0.5rem 0',
                  }}
                >
                  {tourStartDate && format(addDays(tourStartDate, index), 'EEEE, MMMM do')}
                </Box>

                <RichText render={itinerary.description} />
              </Grid.Col>
            </Grid>

            {itinerary.galleryImages?.length > 0 && <ImageGallery galleryImages={itinerary.galleryImages} />}
          </Tabs.Panel>
        );
      })}
    </>
  );
};
