import { graphql } from 'gatsby';
import { addDays } from 'date-fns';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import { formatTitle } from '../../utils/shop';
import { getSliceNumber } from '../../utils/podCast';
import FAQ from '../../components/Tour/TourDetails/Faq';
import BookNow from '../../components/Tour/TourDetails/BookNow';
import Overview from '../../components/Tour/TourDetails/Overview';
import Jumbotron from '../../components/Tour/TourDetails/Jumbotron';
import RelatedContent from '../../components/podcast/RelatedContent';
import SectionNav from '../../components/Tour/TourDetails/SectionNav';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import MainItinerary from '../../components/Tour/TourDetails/MainItinerary';
import PreExtendedItinerary from '../../components/Tour/TourDetails/PreExtendedItinerary';
import PostExtendedItinerary from '../../components/Tour/TourDetails/PostExtendedItinerary';
import WhatsIncludedAndNotIncluded from '../../components/Tour/TourDetails/WhatsIncludedAndNotIncluded';

const TourDetails = ({ data }) => {
  const [activeSection, setActiveSection] = useState('overview');

  const {
    tour: { nodes },
    totalWatchData,
    readData,
    podCastData,
  } = data;

  const [singleTourData] = nodes;

  const jumbotronImage = singleTourData.data.thumbnailImage;
  const title = singleTourData.data.tourTitle.text;
  const subtitle = singleTourData.data.tourSubtitle?.text;
  const description = singleTourData.data.tourDescription?.raw;
  const metaDescription = singleTourData.data.metaDescription;
  const pricePerPerson = {
    singleOccupancy: singleTourData.data.pricePerPersonSingleOccupancy,
    doubleOccupancy: singleTourData.data.pricePerPersonDoubleOccupancy,
  };
  const sliderImages = singleTourData.data.sliderImages;
  const whatsIncludedList = singleTourData.data.whatsIncludedList;
  const whatsNotIncludedList = singleTourData.data.whatsNotIncludedList;
  const itinerarySlices = singleTourData.data.body.filter((item) => item.slice_type === 'itinerary');
  const tourStartDate = new Date(singleTourData.data.tourStartDate);
  const preExtensionStartDate = singleTourData.data.preExtensionStartDate
    ? new Date(singleTourData.data.preExtensionStartDate)
    : null;
  const postExtensionStartDate = singleTourData.data.postExtensionStartDate
    ? new Date(singleTourData.data.postExtensionStartDate)
    : null;

  const tourEndDate = addDays(tourStartDate, itinerarySlices.length - 1);
  const faq = singleTourData.data.faq;
  const preExtensionSlices = singleTourData.data.body1.filter((item) => item.slice_type === 'itinerary');
  const postExtensionSlices = singleTourData.data.body2.filter((item) => item.slice_type === 'itinerary');

  const itineraryFormatter = ({ slices, isExtension }) => {
    return slices.map((slice) => {
      return {
        id: slice.id,
        isExtension,
        description: slice.primary.itinerary_description.raw,
        shortDescription: slice.primary.itinerary_short_description,
        image: slice.primary.itinerary_image,
        galleryImages: slice.items.map((item) => item.gallery_image),
      };
    });
  };

  const itineraryData = itineraryFormatter({ slices: itinerarySlices, isExtension: false });
  const preExtendedItinerary = itineraryFormatter({ slices: preExtensionSlices, isExtension: true });
  const postExtendedItinerary = itineraryFormatter({ slices: postExtensionSlices, isExtension: true });

  const locationPins = singleTourData.data.locationPins;

  const relatedListen = singleTourData.data.relatedListen
    ?.filter((content) => content?.podcast?.uid)
    .map((item) => {
      const pod = podCastData.nodes.find((pod) => pod.uid === item.podcast.uid);
      return {
        ...pod,
        category: 'LISTEN',
        url: `/podcast/${pod.uid}`,
      };
    });

  const relatedEpisodes = singleTourData.data.relatedEpisodes
    ?.filter((content) => content?.episode?.uid)
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.episode.uid);

      return {
        ...watch,
        category: 'WATCH',
        uid: item.episode.uid,
      };
    });

  const relatedRead = singleTourData.data.relatedRead
    ?.filter((content) => content?.read?.uid)
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item?.read?.uid);
      return {
        ...read.data,
        category: 'READ',
        url: read.url,
      };
    });

  const relatedShop = singleTourData.data.relatedProducts
    .filter((item) => !!item.product)
    .map((p) => {
      return {
        thumbnailUrl: p.product.image.src,
        category: 'SHOP',
        title: p.product.title,
        url: `/product/${formatTitle(p.product.title)}`,
      };
    });

  const relatedReadCount = relatedRead.length;
  const relatedEpisodesCount = relatedEpisodes.length;
  const relatedShopCount = relatedShop.length;
  const relatedListenCount = relatedListen.length;

  let relatedData = [];

  if (relatedEpisodes.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedEpisodes.slice(0, sliceNumber));
  }
  if (relatedRead.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedRead.slice(0, sliceNumber));
  }

  if (relatedShop.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedShop.slice(0, sliceNumber));
  }

  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(relatedListen.slice(0, sliceNumber));
  }

  return (
    <BodyClassName className="body-dark tour-details page-watch page-watch--video page-read--single podcast">
      <DefaultLayoutComponent title={title} description={metaDescription}>
        <div className="site-main">
          <div className="bg-transparent" style={{ position: 'relative' }}>
            <div className="wrapper wrapper-xl pd-x-md">
              <Jumbotron
                title={title}
                subtitle={subtitle}
                bgImage={jumbotronImage}
                tourSlug={singleTourData?.uid}
                tourStartDate={tourStartDate}
                tourEndDate={tourEndDate}
              />

              <SectionNav
                activeSection={activeSection}
                hasPreExtension={preExtendedItinerary?.length > 0}
                hasPostExtension={postExtendedItinerary?.length > 0}
              />
              <Overview
                description={description}
                pricePerPerson={pricePerPerson}
                sliderImages={sliderImages}
                setActiveSection={setActiveSection}
              />
              <WhatsIncludedAndNotIncluded
                whatsIncludedList={whatsIncludedList}
                whatsNotIncludedList={whatsNotIncludedList}
                setActiveSection={setActiveSection}
              />
              <MainItinerary
                setActiveSection={setActiveSection}
                itineraryData={itineraryData}
                tourStartDate={tourStartDate}
                locationPins={locationPins}
              />

              {preExtendedItinerary?.length > 0 && (
                <PreExtendedItinerary
                  setActiveSection={setActiveSection}
                  itineraryData={itineraryData}
                  preExtensionStartDate={preExtensionStartDate}
                  preExtendedItineraryData={preExtendedItinerary}
                  locationPins={locationPins}
                />
              )}

              {postExtendedItinerary?.length > 0 && (
                <PostExtendedItinerary
                  setActiveSection={setActiveSection}
                  itineraryData={itineraryData}
                  postExtensionStartDate={postExtensionStartDate}
                  postExtendedItineraryData={postExtendedItinerary}
                  locationPins={locationPins}
                />
              )}

              <BookNow tourSlug={singleTourData?.uid} />

              <FAQ faq={faq} setActiveSection={setActiveSection} />

              <div style={{ marginTop: '121px' }}>
                <RelatedContent relatedContent={relatedData} />
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default TourDetails;

export const query = graphql`
  query TourSinglePage($uid: String!) {
    tour: allPrismicTour(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          thumbnailImage: thumbnail_image {
            url
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
          tourTitle: tour_title {
            text
          }
          tourSubtitle: tour_subtitle {
            text
          }
          tourDescription: description {
            raw
          }
          metaDescription: meta_description
          pricePerPersonSingleOccupancy: price_per_person_single_occupancy
          pricePerPersonDoubleOccupancy: price_per_person_double_occupancy
          sliderImages: slider_images {
            image {
              url
              alt
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          whatsIncludedList: whats_included_list {
            whatsIncluded: whats_included
          }
          whatsNotIncludedList: whats_not_included_list {
            whatsNotIncluded: whats_not_included
          }
          faq {
            answer {
              raw
            }
            question {
              raw
            }
          }
          relatedEpisodes: related_episodes {
            episode {
              uid
              type
              slug
            }
          }
          relatedRead: related_reads {
            read {
              uid
              type
              slug
            }
          }
          relatedListen: related_listens {
            podcast {
              uid
              id
              type
            }
          }
          relatedProducts: related_products {
            product
          }
          body {
            ... on PrismicTourBodyItinerary {
              items {
                gallery_image {
                  alt
                  url
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              id
              primary {
                itinerary_description {
                  raw
                }
                itinerary_short_description
                itinerary_image {
                  alt
                  url
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              slice_label
              slice_type
            }
          }
          body1 {
            ... on PrismicTourBody1Itinerary {
              items {
                gallery_image {
                  alt
                  url
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              id
              primary {
                itinerary_description {
                  raw
                }
                itinerary_short_description
                itinerary_image {
                  alt
                  url
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              slice_label
              slice_type
            }
          }
          body2 {
            ... on PrismicTourBody2Itinerary {
              items {
                gallery_image {
                  alt
                  url
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              id
              primary {
                itinerary_description {
                  raw
                }
                itinerary_short_description
                itinerary_image {
                  alt
                  url
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              slice_label
              slice_type
            }
          }
          tourStartDate: tour_start_date
          preExtensionStartDate: pre_extension_start_date
          postExtensionStartDate: post_extension_start_date
          locationPins: location_pins {
            coordinates {
              latitude
              longitude
            }
            markerDescription: marker_description
            markerImage: marker_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
            name
            isPostExtension: post_extension
            isPreExtension: pre_extension
          }
        }
        uid
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          readDuration: read_duration1 {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
  }
`;
